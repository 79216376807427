<template>
  <v-container>
    <h1>Game List wooo</h1>
  </v-container>
</template>

<script>
  export default {
    name: 'Gamelist',

    data: () => ({
      games: [
          {
              name: "Golf with Friends",
              header: "gwfHeader.png",
          },
      ],
    }),
  }
</script>
